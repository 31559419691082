/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
import { decodeHtml, removeHtmlTags } from '../helpers';
import Placeholder from '../images/placeholderNews2x.png';

function SEO({ description, lang, meta, title, image }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `,
  );
  const decodeText = (text) => removeHtmlTags(decodeHtml(text));

  const metaDescription = description || site.siteMetadata.description;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={decodeText(title)}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      link={[
        {
          rel: 'stylesheet',
          href: './chat/static/css/main.31c07b68.chunk.css', // webchat g4
        },
        {
          rel: 'stylesheet', // webchat g4
          href: 'https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap', // webchat g4
        },
        {
          rel: 'stylesheet', // webchat g4
          href: 'https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap', // webchat g4
        },
      ]}
      script={[
        {
          innerHTML: `
            var newDiv = document.createElement("div");
            newDiv.id = "chat-g4";
            document.body.appendChild(newDiv);
          `, // webchat g4
          defer: true,
        },
        {
          innerHTML: `
            !function (e) { function t(t) { for (var n, a, l = t[0], c = t[1], f = t[2], p = 0, s = []; p < l.length; p++)a = l[p], Object.prototype.hasOwnProperty.call(o, a) && o[a] && s.push(o[a][0]), o[a] = 0; for (n in c) Object.prototype.hasOwnProperty.call(c, n) && (e[n] = c[n]); for (i && i(t); s.length;)s.shift()(); return u.push.apply(u, f || []), r() } function r() { for (var e, t = 0; t < u.length; t++) { for (var r = u[t], n = !0, l = 1; l < r.length; l++) { var c = r[l]; 0 !== o[c] && (n = !1) } n && (u.splice(t--, 1), e = a(a.s = r[0])) } return e } var n = {}, o = { 1: 0 }, u = []; function a(t) { if (n[t]) return n[t].exports; var r = n[t] = { i: t, l: !1, exports: {} }; return e[t].call(r.exports, r, r.exports, a), r.l = !0, r.exports } a.m = e, a.c = n, a.d = function (e, t, r) { a.o(e, t) || Object.defineProperty(e, t, { enumerable: !0, get: r }) }, a.r = function (e) { "undefined" != typeof Symbol && Symbol.toStringTag && Object.defineProperty(e, Symbol.toStringTag, { value: "Module" }), Object.defineProperty(e, "__esModule", { value: !0 }) }, a.t = function (e, t) { if (1 & t && (e = a(e)), 8 & t) return e; if (4 & t && "object" == typeof e && e && e.__esModule) return e; var r = Object.create(null); if (a.r(r), Object.defineProperty(r, "default", { enumerable: !0, value: e }), 2 & t && "string" != typeof e) for (var n in e) a.d(r, n, function (t) { return e[t] }.bind(null, n)); return r }, a.n = function (e) { var t = e && e.__esModule ? function () { return e.default } : function () { return e }; return a.d(t, "a", t), t }, a.o = function (e, t) { return Object.prototype.hasOwnProperty.call(e, t) }, a.p = "./chat/"; var l = this["webpackJsonpflex-chat-react"] = this["webpackJsonpflex-chat-react"] || [], c = l.push.bind(l); l.push = t, l = l.slice(); for (var f = 0; f < l.length; f++)t(l[f]); var i = c; r() }([])
          `, // webchat g4
          defer: true,
        },
        {
          src: './chat/static/js/2.d0673869.chunk.js', // webchat g4
          defer: true,
        },
        {
          src: './chat/static/js/main.ac750434.chunk.js', // webchat g4
          defer: true,
        },
        {
          innerHTML: `
            !function(f,b,e,v,n,t,s)

            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?

            n.callMethod.apply(n,arguments):n.queue.push(arguments)};

            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';

            n.queue=[];t=b.createElement(e);t.async=!0;

            t.src=v;s=b.getElementsByTagName(e)[0];

            s.parentNode.insertBefore(t,s)}(window, document,'script',

            'https://connect.facebook.net/en_US/fbevents.js');

            fbq('init', '1118520486234038');

            fbq('track', 'PageView');
          `, // meta pixel
        },
      ]}
      meta={[
        {
          name: 'image',
          content: image || Placeholder,
        },
        {
          name: 'description',
          content: decodeText(metaDescription),
        },
        {
          property: 'og:title',
          content: title,
        },
        {
          property: 'og:description',
          content: decodeText(metaDescription),
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          name: 'og:image',
          content: image || Placeholder,
        },
        {
          name: 'twitter:card',
          content: 'summary',
        },
        {
          name: 'twitter:creator',
          content: decodeText(site.siteMetadata.author),
        },
        {
          name: 'twitter:title',
          content: decodeText(title),
        },
        {
          name: 'twitter:description',
          content: decodeText(metaDescription),
        },
        {
          name: 'twitter:image',
          content: image || Placeholder,
        },
      ].concat(meta)}
    />
  );
}

SEO.defaultProps = {
  lang: 'pt-BR',
  meta: [],
  description: '',
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  image: PropTypes.string,
};

export default SEO;
